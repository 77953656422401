import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0033 = () => (
  <Wrapper>
    <ColumnH1 label="休業と休暇は何が違う？意外に知らない基礎知識を解説します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.08.26</p>
    <section>
      <img src="/images/column/details/c0033.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        働き方改革の施行とコロナ禍があいまって、労務管理上も休業、休暇などの定義が曖昧となっているケースを目の当たりにします。今回は休業と休暇の違い、その他、実務上の事例を交え、解説していきます。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">休業とは</a>
          </li>
          <li>
            <a href="#toc2">休暇とは</a>
          </li>
          <li>
            <a href="#toc3">休業と休暇の違い</a>
          </li>
          <li>
            <a href="#toc4">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>休業とは</h1>
      </div>
      <p>
        就労義務はあるものの、<span>会社側の都合で就労させないこと</span>
        を指します。
        <br />
        例えば労働者側としては働く意志があるものの、会社が就労させないという状態ですが、具体的には経営難等の理由で資材の獲得ができず、工場が稼働できない場合や、クラスター発生伴い、急遽採用内定者の就労開始時期を繰り下げる場合等が想定されます。
        <br />
        <br />
        休業については、使用者側の責に帰すべき事由であるか否かが問われ、使用者側の責に帰すべき事由であると認められる場合は、
        <span>労働基準法第26条で規定する休業手当</span>
        を支払わなければなりません。尚、休業手当は、労働基準法第12条で規定する平均賃金（算定すべき事由の発生した日以前3か月間に労働者に対し、支払われた賃金の総額をその期間で除した金額）の60％以上の手当を支払わなければなりません。「60％以上」となっていることから、60％未満は最低基準を下回り違法となりますが、60％を超える率で支払う分には問題ありません。
      </p>
      <div id="toc2">
        <h1>休暇とは</h1>
      </div>
      <p>
        就労義務はあるものの、
        <span>労働者側の権利の行使によって、就労義務が免除される日</span>
        を指します。最も馴染みの深い休暇は労働基準法第39条で規定する年次有給休暇が挙げられます。年次有給休暇は労働関係法令上、唯一法律で賃金の支払い義務が明記されている休暇です。
        <br />
        他には同じく労働基準法で規定する生理休暇や育児介護休業法で規定する子の看護休暇も法律上認められている休暇ですが、賃金支払い義務はありません（無給としても違法ではない）。
        <br />
        <br />
        また、事業所独自に定める休暇があります。例えば親族の冠婚葬祭時に限って請求可能な慶弔休暇や夏季に限定して請求可能な夏季休暇は特別休暇として整理されます。
        <br />
        <br />
        休暇については、<span>労働者の権利行使が前提条件</span>
        となりますので、使用者が労働者へ意思確認する前に勝手に付与するということはできません。
      </p>
      <Introduction002 />
      <div id="toc3">
        <h1>休業と休暇の違い</h1>
      </div>
      <p>
        まず、双方が異なる部分として、<span>休業は使用者側が決定する</span>
        という点です（例外は後述）。諸般の事情（例えば所定休日ではないものの取引先が休業しており、働いてもらうだけの仕事がない）で労働者を働かせることが出来ず、「休業」させるか否かは使用者側が判断すべき部分で、労働者側では判断しようがありません。
        <br />
        <br />
        反対に休暇は、
        <span>
          本来は働くべき日ではあるものの、労働者として認められた権利を行使することで、就労義務が消滅
        </span>
        します。すなわち、休暇は労働者側の請求によって、生ずるもので、休業と異なり、使用者側が決定できるものではありません。
        <br />
        <br />
        しかし、実務上、退職前に数日間の年次有給休暇を請求したにも関わらず、全てを消化することができなかったという相談事例があります。請求することは労働者側が持つ権利であることは間違いありません。
        <br />
        しかし、会社の繁忙期で、代替要員も見つからない状況であまりにも長期間休まれてしまうと仕事が回らないというケースも想定されます。その場合、使用者側には、
        <span>「時季変更権」</span>
        という権利が認められています。これは、他の日に年次有給休暇を取得するよう変更をできる権利で、理論上取得することが不可能な、退職日の翌日以後に変更をかけるような権利は認められていませんので注意が必要です。
        <br />
        <br />
        また、休業は画一的に会社側の都合で就労させないというというケースのみと言うことではありません。例えば労働基準法第65条に
        <span>産前産後休業</span>
        があります。まず、「産前」については、出産予定日から6週間（多胎妊娠の場合は14週間）は女性労働者が
        <span>「休業を請求」</span>
        した場合、母体保護の観点から就労させてはなりません。
        <br />
        <br />
        次に「産後」については出産日から8週間を経過しない女性労働者を就労させてはいけません。ただし、産後6週間を経過した女性労働者が請求した場合で、その者について医師が支障ないと認めた場合に限り就労可能とされています。以上のことから、
        <span>
          使用者側の意思決定によって、就労可否を決定する余地がない「休業」もあります
        </span>
        。
        <br />
        <br />
        産前休業については、実務上も出産が遅れることも少なくありませんので、期間が延びるということもあります（産後休業は主産日後8週間のため延びることはありません）。
        <br />
        <br />
        また、<span>休業中に年次有給休暇を取得するということはできません</span>
        。休暇は就労義務がある日に権利を行使することによって、就労義務が免除されることと解されますので、既に就労義務がなくなっている場合、年次有給休暇を取得する余地がありません。
      </p>
      <div id="toc4">
        <h1>最後に</h1>
      </div>
      <p>
        休業と休暇は類似概念として実務上も混同されがちですが、分けて考えておかなければ、違法な労務管理となってしまう場合もあります。
        <br />
        特に労働基準法は罰則が適用される条文も多く、不明な点は専門家等に相談し、早い段階で解決しておくことが肝要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0033
