import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0033 from '../../../components/molecules/columnDetailInner0033'
import UsefulFunctionLayout004 from '../../../components/molecules/usefulFunctionLayout004'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0033 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '意外に知らない！休業と休暇の違いを解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="休業と休暇は何が違う？意外に知らない基礎知識を解説します"
        description="休業は会社側の都合で就労させないこと。休暇は労働者側の権利の行使によって、就労義務が免除される日です。違法な労務管理となってしまわぬよう注意しましょう。"
        ogUrl="https://kintaicloud.jp/column/details/C0033/"
        ogType="article"
        ogTitle="休業と休暇は何が違う？意外に知らない基礎知識を解説します"
        ogDescription="休業は会社側の都合で就労させないこと。休暇は労働者側の権利の行使によって、就労義務が免除される日です。違法な労務管理となってしまわぬよう注意しましょう。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0033.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0033 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout004 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0033

export const pageQuery = graphql`
  query C0033s {
    site {
      siteMetadata {
        title
      }
    }
  }
`
